export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40')
];

export const server_loads = [];

export const dictionary = {
		"/": [18],
		"/dashboard": [19,[2]],
		"/dashboard/activiteit/[activityId]": [20,[2,3]],
		"/dashboard/activiteit/[activityId]/inschrijven/[childId]": [21,[2,3,4]],
		"/dashboard/admin": [22,[2,5]],
		"/dashboard/admin/activiteit/[activityId]": [23,[2,5,6]],
		"/dashboard/admin/betalingen": [24,[2,5]],
		"/dashboard/admin/gebruikers": [25,[2,5,7]],
		"/dashboard/admin/kinderen": [26,[2,5,8]],
		"/dashboard/admin/kinderen/[childId]": [27,[2,5,8,9]],
		"/dashboard/algemene-voorwaarden": [28,[2]],
		"/dashboard/betalingen": [29,[2,10]],
		"/dashboard/kind/toevoegen": [31,[2,12]],
		"/dashboard/kind/[childId]": [30,[2,11]],
		"/dashboard/profiel": [32,[2,13]],
		"/dashboard/registreren": [33,[2]],
		"/dashboard/uitloggen": [34,[2]],
		"/herstel-wachtwoord": [35,[14]],
		"/herstel-wachtwoord/[resetToken]": [36,[14,15]],
		"/jow-bar/[id]": [37,[16]],
		"/kalender": [38],
		"/shop": [39,[17]],
		"/shop/bestellingen": [40,[17]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';